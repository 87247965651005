import React from "react";
import VEZ from "../VEZ/VEZ";
import "./VEZLayout.scss";

const VEZLayout = (props) => {
  return (
    <div
      className="d-flex flex-column"
      style={{
        minHeight: "100vh",
      }}
    >
      <VEZ />
      {props.children}
    </div>
  );
};

export default VEZLayout;
