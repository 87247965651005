import React, { useEffect, useRef } from "react";
import isBrowser from "../../utils/isBrowser";
import "./VEZ.scss";

interface VEZProps {}

const VEZ: React.FC<VEZProps> = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (isBrowser()) {
      const onBeforeUnload = () => {
        sessionStorage.removeItem("vez_loaded");
      };
      window.addEventListener("beforeunload", onBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    }
  }, []);

  useEffect(() => {
    if (isBrowser()) {
      const onMessage: (ev: MessageEvent) => any = (e) => {
        if (e.data === "vez_loaded") {
          sessionStorage.setItem("vez_loaded", "true");
          const iframe = document.getElementById(
            "vez_frame"
          ) as HTMLIFrameElement;
          if (iframe) {
            // iframe.contentWindow?.postMessage(
            //   "increase_area_cards_padding",
            //   "*"
            // );
            // iframe.contentWindow?.postMessage("hide_logo", "*");
          }
        }
      };

      window.addEventListener("message", onMessage);

      return () => {
        window.removeEventListener("message", onMessage);
      };
    }
  }, []);

  return (
    <div className={`vez-iframe`}>
      <iframe
        style={{
          border: "none",
          outline: "none",
          width: "100%",
          height: "100%",
        }}
        name="VEZ"
        src="https://vez.helloar.io/application/5fb32fee1567792cfb89a8ad?areaId=3e82cf90-cb2b-462d-b7e3-63d7d36bfd9c"
        ref={iframeRef}
        id="vez_frame"
      ></iframe>
    </div>
  );
};

export default VEZ;
