// Import all js dependencies.
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.bundle.min";
// normalize CSS across browsers
import "./src/styles/normalize.css";

import React from "react";
import Layout from "./src/components/VEZLayout";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
